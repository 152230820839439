import React from 'react'
import {Wrapper, ContentTop, ContentMid, Content} from './section.style'

function Section({
                     title,
                     desc,
                     backgroundImg,
                     link,
                     leftButton,
                     leftButtonAction,
                     centerButton,
                     centerButtonAction,
                     rightButton,
                     rightButtonAction,
                     fourthButton,
                     fourthButtonAction,
                     arrow
                 }) {

    return (
        <Wrapper bg={backgroundImg}>
            <ContentTop>
                <h1>{title}</h1>
                <p>{desc} <a href='#'>{link}</a></p>
            </ContentTop>

            <div>
                <ContentMid className={arrow ? '' : 'buttons'}>
                    {leftButton &&
                        <div className='left'>
                            <button onClick={leftButtonAction}>{leftButton}</button>
                        </div>
                    }
                    {centerButton &&
                        <div className='left'>
                            <button onClick={centerButtonAction}>{centerButton}</button>
                        </div>
                    }
                    {rightButton &&
                        <div className='left'>
                            <button onClick={rightButtonAction}>{rightButton}</button>
                        </div>
                    }
                    {fourthButton &&
                        <div className='left'>
                            <button onClick={fourthButtonAction}>{fourthButton}</button>
                        </div>
                    }
                </ContentMid>
                {arrow &&
                    <div className='arrow'>
                        <img src='/images/down-arrow.svg' alt='arrow'/>
                    </div>
                }
            </div>
        </Wrapper>
    )
}

export default Section
