import React,{ useState } from 'react'
import { Wrapper,Content,Content1 } from './header.style'
import {Link, useNavigate} from 'react-router-dom';

import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import LoadingSpinner from "../../LoadingSpinner";

const Header = () => {
    const navigate = useNavigate();
    const [open,setOpen] = useState(false);
    const [searchedModel,setSearchedModel] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    function onSearch(model) {
        const ipAddress = localStorage.getItem('userIP');
        const url = `/product/searchProducts?keyword=${searchedModel}&ipAddress=${ipAddress}`;
        setIsLoading(true);
        axios.get(url)
            .then(response => {
                console.log(response.data)
                navigate('/ultraLow', { state: { searchResults: response.data, fromSearch: true, searchTimestamp: new Date().getTime()}});
            })
            .catch(error => {
                console.error("There was an error fetching the product details:", error);
                navigate('/ultraLow', { state: { searchResults: null, fromSearch: true, searchTimestamp: new Date().getTime()}});
            })
            .finally(() => {
                // Set isLoading to false once the request is completed or fails
                setIsLoading(false);
            });
    }

    return (
        <>
        <Wrapper>
            {isLoading ? <LoadingSpinner/> : ""}
            <div className='logo' >
            <Link to='/'>
                <img src='/images/logo.jpg' alt='logo'/>
            </Link>
            </div>
            <Content>
                <Link to='/ultraLow'>Freezer</Link>
                <Link to='/modelx'>Services</Link>
            </Content>
            <Content1>
                <input onChange={(e) => setSearchedModel(e.target.value)} type="text" placeholder="Search..."/>
                <button onClick={onSearch}>Search</button>
            </Content1>
            {open && 
            <div className='top'>
                <div className='close' onClick={()=> setOpen(false)}>
                    <CloseIcon/>
                </div>
            </div> 
            }       
            <SideNav show={open}/>
        </Wrapper>
        </>
    )
}

export default Header