import React, {useEffect, useState} from 'react'
import { Wrapper,Content } from './home.style'

import Section from './Section'
import Header from './Header';
import Footer from './Footer';
import {useNavigate} from "react-router-dom";
import './home.css';
import Modal from "../Modal";
import axios from "axios";

function Home() {

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("Services coming soon, please stay tuned!");

    const goToPage = (page, hash = '') => {
        navigate(`${page}${hash}`);
    }

    const openModalWithContent = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        axios.get('https://geolocation-db.com/json/')
            .then(response => {
                addVisit(response.data.IPv4)
            }, []);
    }, []);

    function addVisit(ip) {
        localStorage.setItem('userIP', ip);
        let url = '/product/addVisit?ipAddress=' + ip
        axios.post(url);
    }


    document.title = "Better Us, Better World";
    return (
        <>
            <Header/>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                content={modalContent}
            />
            <Wrapper>
                <Content>
                    <Section title="Freezers"
                             desc="Leading cooling performance"
                             leftButton={"Dual Cooling"}
                             centerButton={"Cascade ULT(HC)"}
                             rightButton={"Auto-cascade ULT(Mix gas)"}
                             fourthButton={"More"}
                             leftButtonAction={() => goToPage('/ultraLow')}
                             centerButtonAction={() => goToPage('/ultraLow', '#cascade-section')}
                             rightButtonAction={() => goToPage('/ultraLow', '#auto-cascade-section')}
                             fourthButtonAction={() => goToPage('/ultraLow', '#-40')}
                             backgroundImg="ref.jpg"
                             arrow="true"/>
                </Content>
                {/*<Content>*/}
                {/*    <Section title="Intelligent Cold-chain" leftbtn="Details" backgroundImg="inte.jpg" arrow={true}/>*/}
                {/*    <Footer/>*/}
                {/*</Content>*/}
                <Content>
                    <Section title={"Services"} centerButton="Details" centerButtonAction={() => openModalWithContent()} backgroundImg="services.jpg"/>
                </Content>
            </Wrapper>
        </>
    )
}

export default Home