import React, {useState} from "react";

import Header from "../Header";
import "./UltraLowStyle.css";
import { useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner";
import axios from "axios";
import ModalWithInput from "../../ModalWithInput";

const UltraLow = () =>{
    document.title = "Ultra Low Temperature Freezer";

    const location = useLocation();
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [openModalWithInput, setOpenModalWithInput] = useState(false);

    useEffect(() => {
        console.log("is from search :: " + location.state?.fromSearch)
        if (location.state?.fromSearch) {
            console.log("is from search")
            setIsLoading(true);
            const searchResults = location.state?.searchResults; // Assuming search results are passed in location state
            if (searchResults) {
                console.log("search results are " + searchResults)
                setProducts(searchResults);
            }
            setIsLoading(false);
        } else if (location.hash) {
            console.log("from hash")
            const section = document.getElementById(location.hash.substring(1));
            if (section) {
                section.scrollIntoView();
            }
        }
    }, [location, location.state?.searchTimestamp]);

    const navigate = useNavigate();

    const goToPage = (page, model) => {
        const ipAddress = localStorage.getItem('userIP');
        const url = `/product/getProductDetail?model=${model}&ipAddress=${ipAddress}`;

        setIsLoading(true); // Set loading state to true before making the request

        axios.get(url)
            .then(response => {
                console.log(response.data)
                navigate("/details", { state: response.data });
            })
            .catch(error => {
                // Handle any errors here
                console.error("There was an error fetching the product details:", error);
            })
            .finally(() => {
                // Set isLoading to false once the request is completed or fails
                setIsLoading(false);
            });
    };

    function closeInputModalAndOpenAlertModal() {
        setOpenModalWithInput(false)
        setOpenAlertModal(true)
    }


    return(
        <div>
            <Header/>
            {isLoading ? <LoadingSpinner/> : ""}
            <ModalWithInput
                open={openModalWithInput}
                onClose={() => closeInputModalAndOpenAlertModal()}
                onDirectClose={() => setOpenModalWithInput(false)}
            />
            <div className={"mainSection"}>
                <div className={"banner"}>
                    <img className={"bannerBackground"} alt={"banner"} src={"images/pro_bg.jpg"}/>
                    <h1 className={"centered"}>Ultra Low Temperature Freezer</h1>
                </div>
                {location.state?.fromSearch ? (
                    // Dynamically render search results
                    products.length > 0 ? <h2 className={"searchResultsHeader"}>Search Results</h2> : <></>) : <></>
                }
                {location.state?.fromSearch ? (
                    // Dynamically render search results
                    products.length > 0 ? (
                        products.map((product) => (
                            <div key={product.model} className="sections">
                                <ul className={"items"}>
                                    <li>
                                        <div onClick={() => goToPage('/details', product.model)}>
                                            <img className={"thumbnailContain"} alt={product.model} src={product.frontImage} />
                                            <p>{product.productName}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        ))
                    ) : (
                        <h2 className={"noProduct"}>No products found. Please try again or contact us with more details.</h2>
                    )
                ) : (
                    // Your existing static content goes here, unchanged
                    <>
                        <div className={"sections"} id="dual-section">
                            <h2>Dual Cooling -86℃ ULT freezer</h2>
                            <ul className={"items"}>
                                <li>
                                    <div>
                                        <img className={"thumbnailContain"} alt={"DW-HL530"}
                                             src={"images/DW-HL530/DW-HL530-front-min.png"}
                                             onClick={() => goToPage('/details', 'DW-HL530')}/>
                                        <p>DW-HL530</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"DW-HL780"}
                                             src={"images/DW-HL780/DW-HL780-front-min.png"}
                                             onClick={() => goToPage('/details', 'DW-HL780')}/>
                                        <p>DW-HL780</p>
                                    </div>
                                </li>
                            </ul>

                        </div>
                        <div className={"sections"} id="cascade-section">
                            <h2>Cascade ULT(HC)</h2>
                            <ul className={"items"}>
                                <li>
                                    <div>
                                        <img className={"thumbnailContain"} alt={"DW-HL528S"}
                                             src={"images/DW-HL528S/DW-HL528S-front-min.png"}
                                             onClick={() => goToPage('/details', 'DW-HL528S')}/>
                                        <p>DW-HL528S</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnailSpecial"} alt={"DW-HL778S"}
                                             src={"images/DW-HL778S/DW-HL778S-front-min.png"}
                                             onClick={() => goToPage('/details', 'DW-HL778S')}/>
                                        <p>DW-HL778S</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={"sections"} id="auto-cascade-section">
                            <h2>Auto-cascade ULT(Mix gas)</h2>
                            <ul className={"items"}>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"DW-HL50"}
                                             src={"images/DW-HL50/DW-HL50-front-min.jpg"}
                                             onClick={() => goToPage('/details', "DW-HL50")}
                                        />
                                        <p>DW-HL50</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"DW-HL100"}
                                             src={"images/DW-HL100/DW-HL100-front-min.png"}
                                             onClick={() => goToPage('/details', "DW-HL100")}/>
                                        <p>DW-HL100</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnailContain"} alt={"DW-HL398"}
                                             src={"images/DW-HL398/DW-HL398-front-min.jpg"}
                                             onClick={() => goToPage('/details', "DW-HL398")}/>
                                        <p>DW-HL398</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnailContain"} alt={"DW-HL528"}
                                             src={"images/DW-HL528/DW-HL528SA-front-min.png"}
                                             onClick={() => goToPage('/details', "DW-HL528")}/>
                                        <p>DW-HL528</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnailContain"} alt={"DW-HL678"}
                                             src={"images/DW-HL678/DW-HL678-front-min.png"}
                                             onClick={() => goToPage('/details', "DW-HL678")}/>
                                        <p>DW-HL678</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnailContain"} alt={"DW-HL778"}
                                             src={"images/DW-HL778/DW-HL778SA-3.jpg"}
                                             onClick={() => goToPage('/details', "DW-HL778")}/>
                                        <p>DW-HL778</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"DW-HL858"}
                                             src={"images/DW-HL858/DW-HL858.webp"}
                                             onClick={() => goToPage('/details', "DW-HL858")}/>
                                        <p>DW-HL858</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"DW-HL1008"}
                                             src={"images/DW-HL1008/DW-HL1008-front-min.png"}
                                             onClick={() => goToPage('/details', "DW-HL1008")}/>
                                        <p>DW-HL1008</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <img className={"thumbnailSpecial"} alt={"DW-HW50HC"}
                                             src={"images/DW-HW50/DW-HW50-front-min.png"}
                                             onClick={() => goToPage('/details', "DW-HW50HC")}/>
                                        <p>DW-HW50HC</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={"sections"} id="-40">
                            <h2>-40℃ Freezer</h2>
                            <ul className={"items"}>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"DW-FL1008"}
                                             src={"images/DW-FL1008/DW-FL1008-front-min.png"}
                                             onClick={() => goToPage('/details', "DW-FL1008")}/>
                                        <p>DW-FL1008</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={"sections"} id="-25">
                            <h2>-25℃ Freezer</h2>
                            <ul className={"items"}>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"DW-YL450"}
                                             src={"images/DW-YL450/DW-YL450-front-min.jpg"}
                                             onClick={() => goToPage('/details', "DW-YL450")}/>
                                        <p>DW-YL450</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={"sections"} id="2-8">
                            <h2>2-8℃ Pharmacy Refrigerator</h2>
                            <ul className={"items"}>
                                <li>
                                    <div>
                                        <img className={"thumbnail"} alt={"YC-1505L"}
                                             src={"images/YC-1505L/YC-1505L-3.png"}
                                             onClick={() => goToPage('/details', "YC-1505L")}/>
                                        <p>YC-1505L</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={"more"}>
                            <h2>More size and volume are available, please contact us for details.</h2>
                        </div>
                    </>
                )}
            </div>
            <div className={"messageLayer"}>
                <div id="floatShow" rel="nofollow" onClick={() => setOpenModalWithInput(true)}>
                    <p>Contact US</p>
                    <i></i>
                    <div className="animated-circles">
                        <div className="circle c-1"></div>
                        <div className="circle c-2"></div>
                        <div className="circle c-3"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UltraLow;