import React, {useEffect, useMemo, useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { Helmet } from 'react-helmet';
import "./ManagePage.css"

const ManagePage = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [visitTimes, setVisitTimes] = useState(0);
    const [searchInfo, setSearchInfo] = useState([]);
    const [userRequest, setUserRequest] = useState([]);
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [visitInfo, setVisitInfo] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    useMemo(() => {
        axios
            .get('/product/getTotalVisitTimes')
            .then(response => {
                setVisitTimes(response.data)
            })
            .catch(error => {
            });

        axios
            .get('/product/getVisitInfoOrderByVisitTimes')
            .then(response => {
                setVisitInfo(response.data)
            })
            .catch(error => {
            });

        axios
            .get('/product/getTotalProductsCount')
            .then(response => {
                setTotalCount(response.data)
            })
            .catch(error => {
            });

        axios
            .get('/product/getSearchInfo')
            .then(response => {
                setSearchInfo(response.data)
            })
            .catch(error => {
            });

        axios
            .get('/product/getUserInterest')
            .then(response => {
                setUserRequest(response.data)
            })
            .catch(error => {
            });
    }, [])

    function deleteUserRequest(id) {
        setIsLoading(true);
        let url = '/api/deleteUserRequest?id=' + id
        axios
            .put(url)
            .then(response =>
                window.location.reload()
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
            });
    }

    const turnOffTriggeredText = () => {
        document.getElementById("triggeredText").style.display = "none";
    }

    const turnOnTriggeredText = () => {
        document.getElementById("triggeredText").style.display = "block";
    }

    const handleTokenChange = event => {
        setToken(event.target.value);
    };

    return (
        <>
            <body>
            {isLoading ? <LoadingSpinner/> : ""}
            <Helmet>
                <title>Better Us Auto</title>
            </Helmet>
            <div className={"dataDiv"}>
                <h2>Current number of products: {totalCount}</h2>
                <br/>
                <br/>
                <br/>
                <h2>Number of visits: {visitTimes}</h2>
                <br/>
                <br/>
                <br/>
                <div className={"visitStatDiv"}>
                    <h2>Visit Info: </h2>
                    <table className={"visitStatTable"} border="1">
                        <thead>
                        <tr>
                            <th>IP Address</th>
                            <th>Last Visit Time</th>
                            <th>Visit Times</th>
                            <th>Location</th>
                            <th>Viewed Products</th>
                            <th>Searched Products</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            visitInfo.map((info) => {
                                return (
                                    <tr key={info.ipAddress}>
                                        <td>{info.ipAddress}</td>
                                        <td>{info.visitTime}</td>
                                        <td>{info.times}</td>
                                        <td>{info.location}</td>
                                        <td>{info.visitedProduct}</td>
                                        <td>{info.searchedProduct}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <br/>
                <br/>
                <br/>
                <h2>Top search: </h2>
                {
                    searchInfo.map((info) => (
                        <div>
                            <p>{info.split(",")[0]} | {info.split(",")[1]} | {info.split(",")[2]}</p>
                        </div>
                    ))
                }
                <br/>
                <br/>
                <br/>
                <div className={"userRequestDiv"}>
                    <h2>User requests: </h2>
                    <table className={"userRequestTable"} border="1">
                        <thead>
                        <tr>
                            <th>No.</th>
                            <th>Model</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Comment</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            userRequest.map((info) => {
                                const data = info.split(",");
                                return (
                                    <tr key={data[0]}>
                                        <td>{data[0]}</td>
                                        <td>{data[1]}</td>
                                        <td>{data[2]}</td>
                                        <td>{data[3]}</td>
                                        <td>{data[4]}</td>
                                        <td>{data[5]}</td>
                                        <td>{data[6]}</td>
                                        <td>
                                            <button onClick={() => deleteUserRequest(data[0])}>Delete</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                {/*<h3>No. | VIN | First Name | Last Name | Phone | Email | Comment</h3>*/}
                {/*{*/}
                {/*    userRequest.map((info) => (*/}
                {/*        <div>*/}
                {/*            <p>{info.split(",")[0]} | {info.split(",")[1]} | {info.split(",")[2]} | {info.split(",")[3]} | {info.split(",")[4]} | {info.split(",")[5]} | {info.split(",")[6]}</p>*/}
                {/*        </div>*/}
                {/*    ))*/}
                {/*}*/}
            </div>
            </body>
        </>
    )
};

export default ManagePage;