import React, {useState} from "react";
import ImageGallery from "react-image-gallery";

import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import "./Details.css"
import "react-image-gallery/styles/css/image-gallery.css";
import Header from "../Header";
import ModalWithInput from "../../ModalWithInput";

const Details = () =>{
    const location = useLocation();
    const [openModalWithInput, setOpenModalWithInput] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [savedCars, setSavedCars] = useState(new Set());

    function openModal() {
        setOpenModalWithInput(true)
    }

    function closeInputModalAndOpenAlertModal() {
        setOpenModalWithInput(false)
        setOpenAlertModal(true)
    }

    const highlights = location.state.highlight.split(',');
    const imagePaths = location.state.photos.split(',').map(original => ({ original, thumbnail: `${original}?size=w86h64`}))
    const features = location.state.features.split('|')


    return (
        <body>
        <Header/>
        <Helmet>
            {location.state.productName}
        </Helmet>
        <ModalWithInput
            open={openModalWithInput}
            onClose={() => closeInputModalAndOpenAlertModal()}
            onDirectClose={() => setOpenModalWithInput(false)}
            productFromPage={location.state.model}
        />
        <div className={"mainPart"}>
            <div className={"productTitle"}>
                <h1>{location.state.productName}</h1>
            </div>
            <div className="imageSec">
                <div className="block">
                    <section>
                        <ImageGallery
                            items={imagePaths}
                            showFullscreenButton={false}
                        />
                    </section>
                </div>
            </div>
            <div className={"introAndInterestedButton"}>
                <div className="blockIntro">
                    {highlights.map((highlight, index) => (
                        <li key={index} className="briefIntroLi">
                            {highlight.trim()}
                        </li>
                    ))}
                </div>
                <div className="blockButton">
                    {/*<h2> Price: ${location.state.price}</h2>*/}
                    <button onClick={openModal} className={"interestedButton"}>Interested?</button>
                </div>
            </div>
            <div className="detailDiv">
                <div className="overview">
                    <div className={"gradeDiv"}>
                        <h1>Overview</h1>
                        <ul className="briefIntro">
                            {(() => {
                                try {
                                    // Attempt to parse the overview data if it's a string
                                    const overviewData = typeof location.state.overview === 'string' ? JSON.parse(location.state.overview) : location.state.overview;
                                    if (Array.isArray(overviewData)) {
                                        return overviewData.map((item, index) => (
                                            <li key={index} className="briefIntroLi">
                                                <strong>{item.title}</strong>: {item.description}
                                            </li>
                                        ));
                                    }
                                    return <li>Error! Please refresh the page.</li>;
                                } catch (error) {
                                    console.error('Error parsing overview data:', error);
                                    return <li>Error loading overview data</li>;
                                }
                            })()}
                        </ul>
                    </div>
                </div>
                <div className="features">
                    <div className={"gradeDiv"}>
                        <h1>Features</h1>
                        <ul className="briefIntro">
                            {features.map((feature, index) => (
                                <li key={index} className="briefIntroLi">
                                    {feature.trim()}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        </body>
    );
}

export default Details;