import React, {useEffect, useState} from 'react';
import "./ModalWithInput.css"
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

const ModalWithInput = ({ open, onClose, onDirectClose, productFromPage}) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [product, setProduct] = useState("");

    const [firstNameError, setFirstNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    if (!open) return null;

    function submitContactInfo() {
        console.log("product from page is " + productFromPage)
        // Only reset validation errors for fields that have values
        if (firstName) {
            setFirstNameError(false);
        }
        if (email) {
            setEmailError(false);
        }
        if (phone) {
            setPhoneError(false);
        }

        // Check if firstName and email are empty
        if (!firstName) {
            setFirstNameError(true);
        }
        if (!email) {
            setEmailError(true);
        }
        if (!phone) {
            setPhoneError(true);
        }

        // If either firstName or email is empty, return early without making the API call
        if (!firstName || !email | !phone) {
            return;
        }

        setIsLoading(true);
        turnOffNoResultText();
        let url = '';
        if (productFromPage) {
            url = '/product/postUserInterest?' + 'firstName=' + firstName + '&lastName=' + lastName + '&email=' + email + '&phone=' + phone + '&product=' + productFromPage + '&comment=' + comment;
        } else {
            url = '/product/postUserInterest?' + 'firstName=' + firstName + '&lastName=' + lastName + '&email=' + email + '&phone=' + phone + '&product=' + product + '&comment=' + comment;
        }

        axios
            .post(url)
            .then(response => {
                setIsLoading(false);
                onClose();
            })
            .catch(error => {
                setIsLoading(false);
                turnOnNoResultText();
            });
    }

    const turnOnNoResultText = () => {
        document.getElementById("errorText").style.display = "block";
    }

    const turnOffNoResultText = () => {
        document.getElementById("errorText").style.display = "none";
    }

    return (
        <div onClick={onDirectClose} className='overlay'>
            {isLoading ? <LoadingSpinner /> : ""}
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className='modalContainer'
            >
                <button className='closeButton' onClick={onDirectClose}>X</button>
                <div className='modalRight'>
                    <div className='inputDiv'>
                        <h3>Please tell us your info!</h3>
                        <h3>And we will contact you as soon as we can!</h3>
                        <div>
                            <form onSubmit={submitContactInfo}>
                                <label htmlFor="fname">First Name</label>
                                <input type="text" id="fname" name="firstname" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
                                {firstNameError && <p style={{ color: 'red' }}>Please tell us your name.</p>}
                                <label htmlFor="lname">Last Name</label>
                                <input type="text" id="lname" name="lastname" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
                                <label htmlFor="email">Email</label>
                                <input type="text" id="email" name="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                {emailError && <p style={{ color: 'red' }}>Please tell us your email address.</p>}
                                <label htmlFor="phone">Phone Number</label>
                                <input type="text" id="phone" name="phone" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} />
                                {phoneError && <p style={{ color: 'red' }}>Please tell us your phone number.</p>}
                                {productFromPage ? <></> :
                                    <>
                                        <label htmlFor="product">Interested Product</label>
                                        <input type="text" id="product" name="product" placeholder="Product"
                                               onChange={(e) => setProduct(e.target.value)}/>
                                    </>
                                }
                                <label htmlFor="comment">Any comment?</label>
                                <input type="text" id="comment" name="comment" placeholder="Comment" onChange={(e) => setComment(e.target.value)} />
                            </form>
                        </div>
                    </div>
                    <div className='btnContainer'>
                        <button className='btnPrimary' onClick={submitContactInfo}>
                            <span className='bold'>OK</span>
                        </button>
                        <h3 id="errorText">Error happened, please try again</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalWithInput;
