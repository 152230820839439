import React,{ useEffect } from 'react';
import { BrowserRouter as Router ,Routes, Route, Navigate } from 'react-router-dom';

import GlobalStyle from './globalstyles';
import Home from './components/home';
import UltraLow from './components/UltraLow';
import Details from './components/Model-3';
import ModelX from './components/Model-X';
import ModelY from './components/Model_Y';
import ManagePage from "./ManagePage";
function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
          
          <Route path='/' element={<Home/>}/>
          <Route path="/ultraLow" element={<UltraLow/>} />
          <Route path="/guanlizhan" element={<ManagePage/>}/>
          <Route path="/details" element={<Details/>} />
          <Route path="/modelx" element={<ModelX/>} />
          <Route path="/modely" element={<ModelY/>} />

        </Routes>

        <GlobalStyle/>
      </div>
    </Router>
  );
}

export default App;